/* import __COLOCATED_TEMPLATE__ from './import-complete-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
export default Component.extend({
  usersOrLeads: null,
  addedTags: computed('appController.addedTags', 'model.manualTagName', function () {
    if (this.get('appController.addedTags')) {
      return this.get('appController.addedTags');
    } else {
      return [this.get('model.manualTagName')];
    }
  }),
});
