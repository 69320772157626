/* import __COLOCATED_TEMPLATE__ from './csv-file-upload-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { isBlank } from '@ember/utils';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ENV from 'embercom/config/environment';
import { inject as service } from '@ember/service';
import { maxFileSizes } from 'embercom/models/csv-import';

export default class CsvFileUploadComponent extends Component {
  @tracked file;
  @tracked isDragging = false;

  @service intl;

  get fileSizeLimitInMegabytes() {
    return this.args.fileSizeLimitInMegabytes !== undefined
      ? this.args.fileSizeLimitInMegabytes
      : maxFileSizes.default;
  }

  get errorMessage() {
    if (this.file === null) {
      return;
    }

    if (!this.fileHasValidExtension) {
      return this.intl.t('importers.csv-file-upload-component.file-extension-error');
    } else if (this.fileIsTooLarge) {
      return this.intl.t('importers.csv-file-upload-component.file-size-error', {
        maxFileSize: this.fileSizeLimitInMegabytes,
      });
    } else if (this.args.fileFailedToUpload) {
      return this.fileFailedToUploadError;
    }
  }

  get fileFailedToUploadError() {
    return this.args.fileFailedToUploadError
      ? this.args.fileFailedToUploadError
      : this.intl.t('importers.csv-file-upload-component.fallback-upload-error');
  }

  get fileHasValidExtension() {
    let fileName = this.file.name;
    if (!fileName) {
      return false;
    }

    let regexp = new RegExp(`\.csv$`, 'i');
    return fileName.match(regexp);
  }

  get fileIsTooLarge() {
    return this.file.size > this.fileSizeLimitInMegabytes * 1000000;
  }

  _setFile(file) {
    this.file = file;

    if (this.args.setFile && isBlank(this.errorMessage)) {
      this.args.setFile(file);
    }

    if (this.args.showPreview && file) {
      let reader = new FileReader();

      reader.onload = (event) => {
        this.previewURL = event.target.result;
      };

      reader.readAsDataURL(file);
    }
  }

  @action
  pickFile(element) {
    if (ENV.environment === 'test') {
      return this._setFile({ name: 'test.csv' });
    }
    this.fileInput.click();
  }

  @action
  removeFile() {
    this._setFile(null);
    this.previewURL = null;
  }

  @action
  dragEnter(event) {
    event.preventDefault();
    if (!this.args.isDisabled) {
      this.isDragging = true;
    }
  }

  @action
  dragOver(event) {
    this.dragEnter(event);
  }

  @action
  dragLeave(event) {
    event.preventDefault();
    this.isDragging = false;
  }

  @action
  drop(event) {
    event.preventDefault();
    if (!this.args.isDisabled) {
      this._setFile(event.dataTransfer.files[0]);
    }
  }

  @action
  change(event) {
    this._setFile(this.fileInput.files[0]);
  }

  @action
  setFileInput(element) {
    this.fileInput = element;
  }
}
