/* import __COLOCATED_TEMPLATE__ from './import-users-leads-csv.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { action } from '@ember/object';

export default class ImportUsersLeadsCsv extends Component {
  @service store;
  @service intercomEventService;
  @service notificationsService;
  @service appService;
  @service router;
  @tracked recordType = 'users';
  @tracked fileFailedToUpload = false;

  get numberOfOpenCsvImportsForApp() {
    return this.args.csvImports.filter((currentImport) => {
      return (
        currentImport.get('importStatus') !== 'Complete' &&
        currentImport.get('importStatus') !== 'Failed'
      );
    }).length;
  }

  get csvWarningText() {
    return this.numberOfOpenCsvImportsForApp > 1
      ? `There are ${this.numberOfOpenCsvImportsForApp} csv imports currently being processed for this app. We recommend waiting until they have finished before uploading more files.`
      : 'There is 1 csv import currently being processed for this app. We recommend waiting until it has finished before uploading more files.';
  }

  @action
  postCsvUploadFile(file) {
    let formData = new FormData();
    formData.append('app_id', this.appService.app.id);
    formData.append('csv_import[file]', file);
    formData.append('csv_import[record_type]', this.recordType);

    return ajax({
      url: '/ember/csv_imports',
      method: 'POST',
      data: formData,
      contentType: false,
      processData: false,
    });
  }

  @task
  *uploadFile(file) {
    try {
      let uploadedModel = yield this.postCsvUploadFile(file);
      if (uploadedModel) {
        this.intercomEventService.trackAnalyticsEvent({
          // eslint-disable-next-line @intercom/intercom/require-snake-case-analytics
          action: 'csv-file-uploaded',
          // eslint-disable-next-line @intercom/intercom/require-snake-case-analytics
          object: 'csv-import',
        });
        this.store.pushPayload('csv-import', {
          'csv-import': uploadedModel,
        });
        let model = this.store.peekRecord('csv-import', uploadedModel.id);
        this.router.transitionTo('apps.app.import.csv.mapping', model);
      }
    } catch (error) {
      this.notificationsService.notifyError(error.jqXHR.responseJSON.error);
      this.fileFailedToUpload = true;
    }
  }

  @action
  upload(file) {
    this.uploadFile.perform(file);
  }
}
