/* import __COLOCATED_TEMPLATE__ from './tagging-modal.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import { Promise as EmberPromise, resolve } from 'rsvp';
import { computed, action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';

export default Component.extend({
  tagName: '',
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  notificationsService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  intl: service(),
  taggable: computed('autoTagName', function () {
    let promise = new EmberPromise((resolve) => {
      resolve(1);
    });
    return {
      tags: [],
      taggings: [],
      saveTags() {
        return promise;
      },
      updateTaggings() {
        return resolve();
      },
    };
  }),
  url: computed('importType', function () {
    return `ember/${this.get('options.importType')}_imports/set_tags`;
  }),
  setTags: task(function* (tagsToAdd, tagsToRemove) {
    this.set('hasErrored', false);
    this.set('errorMessage', null);
    let appId = this.get('app.id');
    let taggableId = this.get('model.id');
    let autoTagId = this.get('model.manualTagId');
    let params = {
      id: taggableId,
      app_id: appId,
      manual_tag_id: autoTagId,
      added_tags: tagsToAdd.map((tag) => tag.toJSON({ includeId: true })),
      removed_tags: tagsToRemove.map((tag) => tag.toJSON({ includeId: true })),
    };
    yield ajax({
      url: this.url,
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify(params),
    }).then((addedTags) => {
      let addedTagNames = addedTags.map((tag) => {
        tag = JSON.parse(tag);
        return tag.name;
      });
      addedTagNames.unshift(this.get('options.defaultTagName'));
      this.set('options.appController.addedTags', addedTagNames);
      this.notificationsService.notifyConfirmation(
        this.intl.t('apps.app.import.tagging-modal.updated'),
      );
      this.modalService.closeModal();
    });
  }),

  closeModalUsingModalService: action(function () {
    this.modalService.closeModal();
  }),

  actions: {
    performSetTags(tagstoAdd, tagsToRemove) {
      this.setTags.perform(tagstoAdd, tagsToRemove);
    },
  },
});
