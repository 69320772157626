/* import __COLOCATED_TEMPLATE__ from './javascript-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { A } from '@ember/array';
import { computed, action } from '@ember/object';
import { none } from '@ember/object/computed';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  app: null,
  pingDomains: null,
  disableToggle: none('pingDomains'),
  intercomEventService: service(),
  fromOnboarding: false,
  language: null,

  secretKeys: computed('app.api_secret', function () {
    return A([{ userSource: 'web', key: this.get('app.api_secret') }]);
  }),

  identificationHMACGenerator: 'user_id',

  didInsertElement() {
    this._super(...arguments);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'install_option_viewed',
      context: 'javascript',
      place: 'start_guide',
    });
  },

  changeHMACGenerator: action(function (identifier) {
    this.set('identificationHMACGenerator', identifier);
  }),
});
