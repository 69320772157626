/* import __COLOCATED_TEMPLATE__ from './import-set-tag-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { reads } from '@ember/object/computed';
import { computed } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({
  taggableId: null,
  model: null,
  taggedWith: null,
  hasErrored: false,
  errorMessage: null,
  importType: null,
  usersOrLeads: null,
  hasNoCustomTag: null,
  currentSelection: null,
  tags: null,

  addedTags: computed('appController.addedTags', 'model.manualTagName', function () {
    if (this.get('appController.addedTags')) {
      return this.get('appController.addedTags');
    } else {
      return [this.get('model.manualTagName')];
    }
  }),

  defaultTagName: reads('model.manualTagName'),

  actions: {
    openTaggingModalFromImportSetTagComponent() {
      let modalComponent = 'importers/tagging-modal';
      let importModel = this.model;
      let options = {
        importType: this.importType,
        defaultTagName: this.defaultTagName,
        appController: this.appController,
      };
      this.openTaggingModal(modalComponent, importModel, options);
    },
  },
});
