/* import __COLOCATED_TEMPLATE__ from './shopify-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { none } from '@ember/object/computed';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  app: null,
  pingDomains: null,
  disableToggle: none('pingDomains'),
  fromOnboarding: false,
  intercomEventService: service(),

  didInsertElement() {
    this._super(...arguments);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'install_option_viewed',
      context: 'shopify',
      place: 'start_guide',
    });
  },
});
