/* import __COLOCATED_TEMPLATE__ from './grouped-select-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { computed, observer, action } from '@ember/object';
import { schedule, later } from '@ember/runloop';
import { isPresent, isNone } from '@ember/utils';
import IntercomSelectComponent from 'embercom/components/intercom-select-component';
import ENV from 'embercom/config/environment';
import { readOnly } from '@ember/object/computed';

export default IntercomSelectComponent.extend({
  content: null,
  currentCoords: null,
  groups: computed('content', function () {
    return this.content.map(
      function (group, index) {
        group.isFirst = index === 0;
        this.setCoordinatesForRow(group, index);
        return group;
      }.bind(this),
    );
  }),

  setCoordinatesForRow(group, groupIndex) {
    if (isPresent(group.rows)) {
      for (let i = group.rows.length - 1; i >= 0; i--) {
        let row = group.rows[i];

        row.coords = {
          group: groupIndex,
          row: i,
        };
      }
    }
  },

  _maybeSetValueToFirstItem() {
    if (this.shouldSelectFirstItemOnCreate && this.get('content.length') && isNone(this.value)) {
      this.setSelectedOption({
        group: 0,
        row: 0,
      });
    }
  },

  selectedValue: readOnly('value'),

  _createMappedValueForItem(item) {
    return {
      coords: item.coords,
      label: item.label,
      value: item.value,
      icon: item.icon,
      description: item.description,
      tooltip: item.tooltip,
      isSelected: true,
    };
  },

  _calculateDropdownPositioning() {
    if (this.isOpen && !this.isDestroying) {
      schedule('afterRender', this, function () {
        let $dropdown = $('.dropdown', this.element);
        let $titleBox = $('.f__custom-select__box');

        let dropdownHeight = Math.max(
          this.dropdownMinHeight,
          Math.min(this.dropdownMaxHeight, $dropdown.outerHeight()),
        );
        let dropdownWidth = Math.max(
          this.dropdownMinWidth,
          Math.min(this.dropdownMaxWidth, $dropdown.outerWidth()),
        );
        let top = $titleBox.outerHeight();

        $dropdown.css({
          position: 'absolute',
          top: `${top - 1}px`,
          left: '0',
          height: `${dropdownHeight}px`,
          width: `${dropdownWidth}px`,
          overflow: 'auto',
        });
      });
    }
  },

  shouldClear: false,
  shouldClearObserver: observer({
    dependentKeys: ['shouldClear'],

    fn() {
      if (this.shouldClear) {
        let row = this.groups[0].rows[0];
        this.set('value', row);
        this.set('currentCoords', row.coords);
      }
    },

    sync: true,
  }),

  setSelectedOption: action(function (index) {
    let groups = this.groups;
    let group = groups[index.group];
    let row = group.rows[index.row];

    this.set('value', row);
    this.set('currentCoords', index);
    this.set('isOpen', false);
    later(this, this._calculateDropdownPositioning, ENV.APP._25MS); //Recalculate positioning when transform contexts have been removed

    this.action(row);
  }),
});
