/* import __COLOCATED_TEMPLATE__ from './csv-column-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { later } from '@ember/runloop';
import $ from 'jquery';
import { isPresent, isNone } from '@ember/utils';
import { not, notEmpty, bool, equal, and, reads, readOnly } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { valueOrDefault } from '@intercom/pulse/lib/computed-properties';
import { capitalize, dasherize } from '@ember/string';
export default Component.extend({
  customAttributes: null,
  classNames: ['importer__mapping__column-placeholder'],
  classNameBindings: ['isMapped:o__mapped'],
  notificationsService: service(),
  intl: service(),
  rows: null,
  index: null,
  rowCount: null,
  previewRowCount: 6,
  columnsExceedingMaxLength: null,

  rowsLeftCount: computed('rowCount', 'previewRowCount', function () {
    return Math.max(0, this.rowCount - this.previewRowCount);
  }),

  isOpen: false,
  isClosed: not('isOpen'),
  anyOpen: false,
  noneOpen: not('anyOpen'),
  originalLeft: null,
  originalTop: null,
  currentAttributeMapping: null,
  attributeMappings: null,

  shouldNotifyLengthWarning: computed(
    'columnsExceedingMaxLength',
    'isPredefinedAttributeMapping',
    function () {
      return (
        this.columnsExceedingMaxLength &&
        this.columnsExceedingMaxLength.indexOf(this.index) >= 0 &&
        !this.isPredefinedAttributeMapping
      );
    },
  ),

  hasRows: notEmpty('rows'),
  attributeIcon: valueOrDefault('attributeMapping.icon', 'transfer'),

  normalizeAttributeNameWithType(newType, oldType) {
    // Confusingly named, we leave existing custom attributes names alone.
    // We don't try to append _at to existing custom attributes
    if (!this.isSettingCustomAttribute) {
      return;
    }

    let name = this.attributeName;

    if (!isPresent(name)) {
      return;
    }

    if (newType === 'Date') {
      if (!name.match(/_at$/)) {
        name = `${name}_at`;
      }
    } else if (oldType && oldType === 'Date' && newType !== 'Date') {
      if (name.match(/_at$/)) {
        name = name.substr(0, name.length - 3);
      }
    }

    this.set('attributeName', name);
  },

  columnNumber: computed('index', function () {
    return this.index + 1;
  }),

  hasHeader: false,

  headerRow: computed('hasHeader', 'rows', function () {
    return this.hasHeader ? this.rows[0] : null;
  }),

  bodyRows: computed('hasHeader', 'rows', function () {
    return this.hasHeader ? this.rows.slice(1) : this.rows;
  }),

  attributeMapping: computed('attributeMappings.@each.columnIndex', 'index', function () {
    let attributeMappings = this.attributeMappings;
    let index = this.index;

    return attributeMappings.find(function (attributeMapping) {
      return attributeMapping.columnIndex === index;
    });
  }),

  isMapped: bool('attributeMapping'),
  isNotMapped: equal('isMapped', false),
  isSettingCustomAttribute: false,

  get attributeTypes() {
    return [
      {
        label: this.intl.t('importers.csv-column-component.attribute-text.label'),
        value: 'String',
        tooltip: this.intl.t('importers.csv-column-component.attribute-text.tooltip'),
      },
      {
        label: this.intl.t('importers.csv-column-component.attribute-number.label'),
        value: 'Fixnum',
        tooltip: this.intl.t('importers.csv-column-component.attribute-number.tooltip'),
      },
      {
        label: this.intl.t('importers.csv-column-component.attribute-float.label'),
        value: 'Float',
        tooltip: this.intl.t('importers.csv-column-component.attribute-float.tooltip'),
      },
      {
        label: this.intl.t('importers.csv-column-component.attribute-date.label'),
        value: 'Date',
        tooltip: this.intl.t('importers.csv-column-component.attribute-date.tooltip'),
      },
      {
        label: this.intl.t('importers.csv-column-component.attribute-boolean.label'),
        value: 'Boolean',
        tooltip: this.intl.t('importers.csv-column-component.attribute-boolean.tooltip'),
      },
    ];
  },

  selectedAttributeType: null,

  attributeNameAndAttributeTypeAreValid: and(
    'attributeNameIsValid',
    'selectedAttributeTypeIsValid',
  ),

  attributeNameAndAttributeTypeAreInvalid: not('attributeNameAndAttributeTypeAreValid'),

  attributeNameIsValid: computed('attributeName', function () {
    let attributeName = this.attributeName;
    return typeof attributeName === 'string' && attributeName.length > 0;
  }),

  selectedAttributeTypeIsValid: computed('selectedAttributeType', function () {
    let selectedAttributeType = this.selectedAttributeType;
    return typeof selectedAttributeType === 'string' && selectedAttributeType.length > 1;
  }),

  isPredefinedAttributeMapping: reads('currentAttributeMapping'),
  numberOfPredefinedAttributes: readOnly('predefinedAttributeMappings.length'),
  attributeName: null,

  click() {
    if (this.isClosed && this.noneOpen) {
      this.open(() => {
        $(document).on('click.csv-column', (event) => {
          if (!this.isDestroying) {
            if (!$(event.target).closest('.importer__mapping__column.o__open').length) {
              this.close();
            }
          }
        });
      });
    }
  },

  // The attribute 'type' as defined in the Attribute model is different than
  // the 'type' used in the CSV column groups
  // This returns the type used in CSV column groups
  attributeTypeMapping: {
    integer: 'Fixnum',
    date: 'Date',
    boolean: 'Boolean',
    string: 'String',
    float: 'Float',
  },

  _attributeToRow(attr) {
    return {
      label: attr.get('name'),
      type: this.attributeTypeMapping[attr.get('type')],
      value: attr.get('name'),
    };
  },

  matchingCustomAttributes: computed(
    'intl.locale',
    'rows',
    'customAttributes',
    'hasRows',
    function () {
      let rows = [];

      if (this.hasRows) {
        let header = this.rows[0];
        if (isPresent(header) && header.length > 1) {
          header = capitalize(dasherize(header));
          this.customAttributes.forEach((attr) => {
            let title = capitalize(dasherize(attr.get('name')));

            if (title.indexOf(header) > -1) {
              let row = this._attributeToRow(attr);
              rows.push(row);
            }
          });
        }
      }
      return {
        title: this.intl.t('importers.csv-column-component.matching-custom-attributes'),
        rows,
      };
    },
  ),

  isAttributeTypeDisabled: computed(
    'isPredefinedAttributeMapping',
    'disabledAttributeSelection',
    'isSettingCustomAttribute',
    function () {
      if (this.isPredefinedAttributeMapping) {
        return true;
      }

      return this.disabledAttributeSelection;
    },
  ),

  otherCustomAttributes: computed(
    'intl.locale',
    'customAttributes',
    'matchingCustomAttributes',
    'alreadyUsedAttributes',
    'attributeMappings',
    function () {
      let ignoredList = this.matchingCustomAttributes.rows;
      let hasMatching = ignoredList.length > 0;
      ignoredList = ignoredList.concat(this.alreadyUsedAttributes.rows);
      ignoredList = ignoredList.mapBy('label');

      let rows = this.customAttributes
        .map((attr) => {
          return this._attributeToRow(attr);
        })
        .reject(function (row) {
          return ignoredList.indexOf(row.label) > -1;
        });

      rows = rows.sortBy('label');

      return {
        title: hasMatching
          ? this.intl.t('importers.csv-column-component.other-custom-attributes')
          : this.intl.t('importers.csv-column-component.existing-custom-attributes'),
        rows,
      };
    },
  ),

  alreadyUsedAttributes: computed(
    'intl.locale',
    'attributeMappings.[]',
    'numberOfPredefinedAttributes',
    function () {
      let rows = [];
      let startIndex = this.numberOfPredefinedAttributes - 1;

      this.attributeMappings.forEach(function (attribute, index) {
        if (index > startIndex) {
          let row = {
            label: attribute.label,
            value: attribute.label,
            disabled: true,
            type: attribute.type,
          };
          rows.push(row);
        }
      });

      rows = rows.sortBy('label');

      return {
        title: this.intl.t('importers.csv-column-component.already-mapped-attributes'),
        rows,
      };
    },
  ),

  customAttributeGroups: computed(
    'intl.locale',
    'matchingCustomAttributes',
    'otherCustomAttributes',
    'alreadyUsedAttributes',
    function () {
      let placeholderGroup = {
        rows: [
          {
            label: this.intl.t('importers.csv-column-component.select-attribute'),
            value: '',
          },
        ],
      };

      let createNewGroup = {
        rows: [
          {
            label: this.intl.t('importers.csv-column-component.new-attribute'),
            value: null,
          },
        ],
      };

      let sections = [
        placeholderGroup,
        this.matchingCustomAttributes,
        this.otherCustomAttributes,
        this.alreadyUsedAttributes,
        createNewGroup,
      ];

      // Hide empty sections
      sections = sections.reject((section) => {
        return section.rows.length === 0;
      });

      return sections;
    },
  ),

  open(callback) {
    if (this.isMapped) {
      return;
    }

    if (this.shouldNotifyLengthWarning) {
      this.notificationsService.notifyWarning(
        this.intl.t('importers.csv-column-component.lengthy-warning'),
        5000,
      );
    }

    this.set('isOpen', true);
    this.set('anyOpen', true);
    this.set('shouldClearDropdown', false);

    this.set('attributeName', this.get('currentAttributeMapping.name'));
    this.set('attributeLabel', this.get('currentAttributeMapping.label'));
    this.set('selectedAttributeType', this.get('currentAttributeMapping.type'));

    let $column = $('.importer__mapping__column', this.element);

    let viewportCenterLeft = $(window).width() / 2;
    let viewportCenterTop = $(window).height() / 2;

    let columnLeft = $column.offset().left;
    let columnTop = $column.offset().top;

    let columnWidthOffset = $column.width() / 2;
    let columnHeightOffset = $column.height() / 2;

    $column.animate(
      {
        left: viewportCenterLeft - columnLeft - columnWidthOffset - 140,
        top: viewportCenterTop - columnTop - columnHeightOffset,
        width: '560px',
      },
      {
        duration: 250,
        done: callback,
      },
    );

    this.bindKeyListeners();
  },

  columnClass: computed('columnNumber', 'anyOpen', function () {
    let anyOpen = this.anyOpen ? 'open' : 'closed';
    return `${this.columnNumber}:${anyOpen}`;
  }),

  close() {
    $(document).off('click.csv-column');

    // Don't leave the text field as red
    if ($('.f__attribute_name_field', this.element)) {
      $('.f__attribute_name_field', this.element).removeClass('o__error');
    }

    let $column = $('.importer__mapping__column', this.element);

    $column.animate(
      {
        left: null,
        top: null,
        width: '280px',
      },
      {
        duration: 250,
        done: function () {
          if (this.isDestroyed) {
            return;
          }
          this.set('isOpen', false);
          this.set('anyOpen', false);
          this.set('shouldClearDropdown', true);
          this.resetCustomAttribute();
        }.bind(this),
      },
    );

    this.unbindKeyListeners();
  },

  confirm() {
    let index = this.index;

    if (this.isPredefinedAttributeMapping) {
      this.setAttributeMapping({
        columnIndex: index,
      });
    } else {
      let type = this.selectedAttributeType;
      this.normalizeAttributeNameWithType(type);

      let name = this.attributeName;

      let nameAlreadyExists = !!this.attributeMappings.find((attr) => attr.name === name);

      if (nameAlreadyExists) {
        let errorText = this.intl.t('importers.csv-column-component.name-already-exists', { name });
        this.notificationsService.notifyError(errorText);

        // Highlight the text field in an error state
        if ($('.f__attribute_name_field', this.element)) {
          $('.f__attribute_name_field', this.element).addClass('o__error');
        }

        return;
      } else {
        this.setAttributeMapping({
          columnIndex: index,
          label: name,
          name,
          type,
        });
      }
    }

    this.close();
  },

  bindKeyListeners() {
    let enterKeyCode = 13;
    let escapeKeyCode = 27;

    $(document).on('keydown.csv-column', (event) => {
      if (!this.isDestroying) {
        if (event.keyCode === enterKeyCode) {
          if (this.attributeNameAndAttributeTypeAreValid) {
            this.confirm();
          }
        } else if (event.keyCode === escapeKeyCode) {
          this.close();
        }
      }
    });
  },

  unbindKeyListeners() {
    $(document).off('keydown.csv-column');
  },

  resetCustomAttribute() {
    this.set('attributeName', '');
    this.set('disabledAttributeSelection', true);
    this.set('selectedAttributeType', null);
    this.set('isSettingCustomAttribute', false);
  },

  removeErrorState: action(function () {
    $('.f__attribute_name_field', this.element).removeClass('o__error');
  }),

  cancel: action(function () {
    this.close();
  }),

  confirmAction: action(function () {
    this.confirm();
  }),

  changedCustomAttribute: action(function (row) {
    if (isPresent(row)) {
      this.set('disabledAttributeSelection', false);
      this.set('selectedAttributeType', '');

      // 'Select Custom Attribute'
      if (row.value === '') {
        this.set('attributeName', '');

        // Attribute selection is disabled when nothing selected
        this.set('disabledAttributeSelection', true);
        return;
        // 'Create a new custom attribute'
      } else if (isNone(row.value)) {
        this.set('attributeName', '');
        this.set('isSettingCustomAttribute', true);

        // You can set attributes manually
        this.set('disabledAttributeSelection', false);

        if (this.hasHeader) {
          let rows = this.rows;
          if (rows.length) {
            this.set('attributeName', rows[0]);
          }
        }

        // FOCUS
        if ($('.f__attribute_name_field', this.element)) {
          later(
            this,
            function () {
              $('.f__attribute_name_field', this.element).focus();
            },
            100,
          );
        }
      } else {
        this.set('attributeName', row.value);
        if (isPresent(row.type)) {
          this.set('selectedAttributeType', row.type);
          this.set('disabledAttributeSelection', true);
        } else {
          this.set('disabledAttributeSelection', false);
        }
      }
    }
  }),

  actions: {
    setSelectedAttributeType(attributeTypeValue) {
      if (this.isPredefinedAttributeMapping || !this.isSettingCustomAttribute) {
        return;
      }
      this.normalizeAttributeNameWithType(attributeTypeValue, this.selectedAttributeType);
      this.set('selectedAttributeType', attributeTypeValue);
    },

    resetCustomAttribute() {
      this.resetCustomAttribute();
    },
  },
});
