/* import __COLOCATED_TEMPLATE__ from './user-source-selector-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { dasherize } from '@ember/string';
import { computed, action } from '@ember/object';
import { not, readOnly, and, mapBy, union } from '@ember/object/computed';
import Component from '@ember/component';
import { containsByProperty, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import INSTALL_OPTIONS from 'embercom/lib/guide/start-screen/installation-options';
import { inject as service } from '@ember/service';

export default Component.extend({
  intercomEventService: service(),
  showDescription: null,
  showAllOptions: false,
  showlimitedOptions: not('showAllOptions'),
  showSourceInstalled: readOnly('showAllOptions'),
  showExistingSources: and('webMobileThirdPartyUserSources.length', 'showlimitedOptions'),
  userSource: null,
  userSources: null,
  userSourceKeys: mapBy('userSources', 'name'),
  userSourceInstalled: containsByProperty('userSourceKeys', 'userSource'),
  webMobileThirdPartyUserSources: union('webSources', 'mobileSources', 'thirdPartySources'),
  displayWebSources: ternaryToProperty('showExistingSources', 'webSources', 'webOptions'),
  displayMobileSources: ternaryToProperty('showExistingSources', 'mobileSources', 'mobileOptions'),

  displayThirdPartySources: ternaryToProperty(
    'showExistingSources',
    'thirdPartySources',
    'thirdPartyOptions',
  ),

  webOptions: INSTALL_OPTIONS['web'],
  mobileOptions: INSTALL_OPTIONS['mobile'],
  thirdPartyOptions: INSTALL_OPTIONS['thirdParty'],

  webSources: computed('webOptions.[]', 'userSourceKeys.[]', function () {
    return this.webOptions.filter((option) => {
      return this.userSourceKeys.includes(option.key);
    });
  }),

  mobileSources: computed('mobileOptions.[]', 'userSourceKeys.[]', function () {
    return this.mobileOptions.filter((option) => {
      return this.userSourceKeys.includes(option.key);
    });
  }),

  thirdPartySources: computed('thirdPartyOptions.[]', 'userSourceKeys.[]', function () {
    return this.thirdPartyOptions.filter((option) => {
      return this.userSourceKeys.includes(option.key);
    });
  }),

  userSourceComponentName: computed('userSource', function () {
    return `${dasherize(this.userSource)}-component`;
  }),

  init() {
    this._super(...arguments);
    if (this.showAllOptions) {
      return;
    }
    if (this.get('webMobileThirdPartyUserSources.length') === 1) {
      //if there is only one userSource object render the component directly
      this.set('userSource', this.get('webMobileThirdPartyUserSources.firstObject.key'));
    }
  },

  chooseUserSource: action(function (option) {
    this.set('userSource', option);
    this.set('errorMessage', null);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'install_option_chosen',
      context: option,
      place: 'start_guide',
    });
    if (this.chooseUserSourceAction) {
      this.chooseUserSourceAction(option);
    }
  }),

  verifyUserSource: action(function (userSource) {
    this.completeAction(userSource);
  }),
});
