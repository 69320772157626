/* import __COLOCATED_TEMPLATE__ from './mixpanel-import-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { equal, notEmpty, and, not, or } from '@ember/object/computed';
import Component from '@ember/component';
import { task } from 'ember-concurrency';

export default Component.extend({
  intercomEventService: service(),
  store: service(),
  intl: service(),
  step1Active: equal('currentStep', 1),
  step2Active: equal('currentStep', 2),
  step3Active: equal('currentStep', 3),

  currentStep: 1,

  mixpanelApiKey: '',
  mixpanelApiSecret: '',

  hasEnteredApiKey: notEmpty('mixpanelApiKey'),
  hasEnteredApiSecret: notEmpty('mixpanelApiSecret'),
  hasEnteredCredentials: and('hasEnteredApiKey', 'hasEnteredApiSecret'),
  hasNotEnteredCredentials: not('hasEnteredCredentials'),

  mixpanelError: '',

  isCheckingCredentials: false,
  cannotFinish: or('isCheckingCredentials', 'hasNotEnteredCredentials'),
  checkCredentials: task(function* () {
    try {
      let mixpanelResponse = yield this.store
        .createRecord('mixpanel-import', {
          apiKey: this.mixpanelApiKey,
          apiSecret: this.mixpanelApiSecret,
          appIdCode: this.appId,
        })
        .save();
      if (mixpanelResponse) {
        this.set('model', mixpanelResponse);
      }
      window.mixpanel_import_id = mixpanelResponse.id;
      this.transitionToTag();
    } catch (error) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'mailchimp-import-authentication-failed',
        object: 'mailchimp-import',
      });
      this.set(
        'mixpanelError',
        this.intl.t('importers.mixpanel-import-component.api-wrong-credentials'),
      );
    }
  }),
  previousStep: action(function () {
    this.decrementProperty('currentStep');
  }),
  nextStep: action(function () {
    this.incrementProperty('currentStep');
  }),
});
